<template>
	<h1>Lists</h1>
	<div class="text-block bump-b-30">
		<p>
			We have a few useful built-in list stylings.<br />For expandable lists, check the
			<router-link :to="{ name: 'DocComponentsAccordeonList' }">accordeon list</router-link>
		</p>
	</div>

	<!-- Default -->
	<div class="text-block bump-b-30 left-right">
		<pre v-highlightjs><code class="language-html">{{html1.join('\n')}}</code></pre>
		<div>
			<h3>Default</h3>
			<ul>
				<li>Foo</li>
				<li>Bar</li>
				<li>Goo</li>
				<li>Har</li>
			</ul>
		</div>
	</div>

	<!-- All rowds padded -->
	<div class="text-block bump-b-30 left-right">
		<pre v-highlightjs><code class="language-html">{{html2.join('\n')}}</code></pre>
		<div>
			<h3>Padded list</h3>
			<ul class="pad">
				<li>Foo</li>
				<li>Bar</li>
				<li>Goo</li>
				<li>Har</li>
			</ul>
		</div>
	</div>

	<!-- Some rowds padded -->
	<div class="text-block bump-b-30 left-right">
		<pre v-highlightjs><code class="language-html">{{html3.join('\n')}}</code></pre>
		<div>
			<h3>Individual row padding</h3>
			<ul>
				<li>Foo</li>
				<li class="pad">Bar</li>
				<li>Goo</li>
				<li>Har</li>
			</ul>
		</div>
	</div>

	<!-- Checklist -->
	<div class="text-block bump-b-30 left-right">
		<pre v-highlightjs><code class="language-html">{{html4.join('\n')}}</code></pre>
		<div>
			<h3>Checklist</h3>
			<ul>
				<li class="ok">Foo</li>
				<li class="not-ok">Bar</li>
				<li class="ok">Goo</li>
				<li class="not-ok">Har</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { injectMetaData } from '@/use/MetaData'

export default {
	name: 'DocCssLists',
	components: {},
	setup() {
		injectMetaData({
			title: 'Doc: CSS Lists',
		})
	},
	data() {
		return {
			// prettier-ignore
			html1: [
				'<ul>',
				'	<li>Foo</li>',
				'	<li>Bar</li>',
				'	<li>Goo</li>',
				'	<li>Har</li>',
				'</ul>'
			],
			html2: [
				'<ul class="pad">',
				'	<li>Foo</li>',
				'	<li>Bar</li>',
				'	<li>Goo</li>',
				'	<li>Har</li>',
				'</ul>',
			],
			html3: [
				'<ul>',
				'	<li>Foo</li>',
				'	<li class="pad">Bar</li>',
				'	<li>Goo</li>',
				'	<li>Har</li>',
				'</ul>',
			],
			html4: [
				'<ul>',
				'	<li class="ok">Foo</li>',
				'	<li class="not-ok">Bar</li>',
				'	<li class="ok">Goo</li>',
				'	<li class="not-ok">Har</li>',
				'</ul>',
			],
		}
	},
	methods: {},
}
</script>

<style scoped lang="scss">
.text-block.left-right {
	display: flex;
	flex-direction: row;
	gap: 0.2rem;
}
.text-block.left-right > pre,
.text-block.left-right > div {
	flex: 1 1;
	height: 100%;
	margin: 0;
}
</style>
